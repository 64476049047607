import React from 'react';
import './OurTeam.css';

const teamMembers = [
  {
    name: 'Jose Cruz',
    position: 'President & CEO',
  },
  {
    name: 'Haroon Najam',
    position: 'COO',
  },
  {
    name: 'Eduardo Robles',
    position: 'CFO',
  },
  {
    name: 'Henry Lopez',
    position: 'VP SALES & MARKETING',
  },
  {
    name: 'Vinnicio Castillo',
    position: 'VP OPERATIONS & LOGISTICS',
  },
  
  {
    name: 'Anita Poon',
    position: 'VP FINANCE',
  },
];

const HexagonTeam = () => {
  return (
    <div className="hexagon-team-container">
      <h2 className="team-heading">OUR TEAM</h2>
      <p className="team-intro">
        At Hexagon Scaffolding Inc., our dedicated team is committed to providing the highest standards of scaffolding and training services. Each member of our team brings a wealth of experience and expertise, ensuring that we meet and exceed industry standards. We take pride in our collaborative approach to every project, delivering exceptional results for our clients.
      </p>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <h3 className="member-name">{member.name}</h3>
            <p className="member-position">{member.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HexagonTeam;
