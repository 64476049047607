import React from "react";
import { Link } from "react-router-dom";
import "./Products.css"; // Make sure to style these appropriately

const productImages = {
  "Suspended Scaffolding Platforms":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FpzHQLYq%20-%20Imgur.png?alt=media&token=e2bd12ce-901f-476c-a432-27f61d09226e",
  "Suspended Scaffolding Motors":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2F4yRkzqi%20-%20Imgur.png?alt=media&token=3fef60ae-11cd-4829-8300-7233e08d1663",
  "Suspended Scaffolding Outrigger Systems":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2Faq0gnPT%20-%20Imgur.png?alt=media&token=703aa993-eb76-4746-acf6-b87b52e35af6",
  "Suspended Scaffolding Accessories":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2F894IMGy%20-%20Imgur.png?alt=media&token=fc6e0fc5-01a0-4088-ad81-aaf8d8c91f78",
};

// Mapping of item names to their corresponding URLs
const productLinks = {
  "SKYCLIMBER MKD MODULAR PLATFORM MANUAL":
    "https://skyclimber.com/wp-content/assets/operator-s-instruction-manual-mkd-mkd2---sc510.pdf",
  "SKY CLIMBER SKY STAGE ULTRA (SSU) MANUAL":
    "https://swingstaging.com/wp-content/uploads/2021/09/SKY-CLIMBER-SKY-STAGE-ULTRA-SSU-MANUAL.pdf",
  "SKY CLIMBER SERVICE BULLETIN #151":
    "https://www.sigmaaldrich.com/deepweb/assets/sigmaaldrich/product/documents/961/869/al_techbull_al151.pdf?srsltid=AfmBOopU7bDuawXqZaYetGB0Q5dEXsX5DmYS0aOghKXNDYl8ufUdYkvY",
  " ALTREX, BEE ACCESS MANUAL":
    "https://beeaccess.com/wp-content/uploads/2023/06/altrex-modular-platform-system-manual.pdf",
  "ALL ALTREX MANUALS":
    "https://amscaf.com/wp-content/uploads/2017/07/Altrex-Assembly-Operating-Instructions.pdf",
  "WINSAFE ALL MANUALS":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Platforms%2FWINSAFE-SUPERMOD-PLATFORMS-MANUAL.pdf?alt=media&token=1b5d2391-64a0-4c8d-ac4b-83cd4cdd22fe",
  "WINSAFE SUPERMOD PLATFORMS MANUAL":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Platforms%2FWINSAFE-SUPERMOD-PLATFORMS-MANUAL.pdf?alt=media&token=1b5d2391-64a0-4c8d-ac4b-83cd4cdd22fe",
  "WINSAFE SUPERMOD PLATFORM OPERATING INSTRUCTIONS":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Platforms%2FWINSAFE-SUPERMOD-PLATFORMS-MANUAL.pdf?alt=media&token=1b5d2391-64a0-4c8d-ac4b-83cd4cdd22fe",
  "TRACTEL® THRUSAFe® MODULAR PLATFORM FOR CONFINED SPACES":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Platforms%2FTRACTEL%C2%AE-THRUSAFE%C2%AE-MODULAR-PLATFORM-FOR-CONFINED-SPACES.pdf?alt=media&token=56c91c88-6663-4bf1-b2dc-ecfea986531b",
  "TRACTEL® SOLSIT® BOSUN CHAIR MANUAL":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Platforms%2FTRACTEL%C2%AE-SOLSIT%C2%AE-BOSUN-CHAIR-MANUAL.pdf?alt=media&token=072f072d-e160-41af-bf23-33bfca6b3be5",
  "TRACTEL® SKYSAFE® MODULAR AND KNOCKDOWN PLATFORMS MANUAL":
    "https://www.tractel.com/PIM/NA_Products/NA_Manuals/Temporary_Access/P4766_TA_ONT%20Skysafe%20Platform%20Manual%20Ontario_2016.pdf",
  "TRACTEL® MODULAR SCAFFOLD VARIANCE":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Platforms%2FTRACTEL%C2%AE-MODULAR-SCAFFOLD-VARIANCE.pdf?alt=media&token=048da5f4-50fd-4154-a2ee-19d43db852c5",
  "LOUISVILLE PLATFORMS (ROPE SCAFFOLD)":
    "https://snapperindustrialproducts.com/wp-content/uploads/Louisville_Ladder_2013_Full_Line_Catalog.pdf",

  // Add links for all other items similarly...
  "TRACTEL® TIRAK® TE MANUAL":
    "https://www.tractel.com/PIM/NA_Products/NA_Manuals/Temporary_Access/G483_TA_XA_Instruction_Manual.pdf",
  "TRACTEL® TIRAK MOTORS 2015 EDITION MANUAL":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Motors%2FTirakxseries2015manual.pdf?alt=media&token=f1c7fdc8-4a9a-4406-a485-4261ba392137",
  "TRACTEL® TMS-600 MANUAL HOIST MANUAL":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Motors%2FTMS600-Manual.pdf?alt=media&token=475b0d2d-96a4-4fa2-884b-28b3c04bc186",
  "SKYCLIMBER ALPHA HOIST 1000":
    "https://skyclimber.com/wp-content/assets/alpha-1000-hoist-w-pendant.pdf",
  "SKYCLIMBER CX 1250 HOIST": "https://skyclimber.com/product/compact-1250/",
  "SKYCLIMBER® MODEL 4 HOIST":
    "https://swingstaging.com/wp-content/uploads/2021/09/skymod4hoist.pdf",
  "SPIDER SC1000 / SC1500 OPERATOR'S MANUAL":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Motors%2F9961-E-Operators-Manual-Spider-SC1000-SC1500-Hoists-1.pdf?alt=media&token=5663c06b-40bf-4275-899c-b0b44575d03b",
  "SKYCLIMBER COMPACT 750 HOIST MANUAL":
    "https://skyclimber.com/wp-content/assets/compact_hoists_750_1000_1250_1500.pdf",
  "SKYCLIMBER COMPACT 1000 HOIST MANUAL":
    "https://skyclimber.com/wp-content/assets/compact_hoists_750_1000_1250_1500.pdf",
  "GRIPHOIST MECHANICAL HOIST MANUAL":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Motors%2Fgripmanual.pdf?alt=media&token=95e4c967-6db6-4916-8fda-7f4c343fd0b8",
  "LNX OPERATOR'S MANUAL 03112014":
    "https://skyclimber.com/wp-content/uploads/2017/10/lnx-operator-s-manual-03112014.pdf",
  "GENERATORS QUICK REFERENCE":
    "https://apps.dnr.wi.gov/doclink/waext/wa1821.pdf",
  "POWER CLIMBER 1000 PC1":
    "https://irp-cdn.multiscreensite.com/5bccb9aa/files/uploaded/PC1_Oct_2012_Final.pdf",
  "SPIDER NEW YORKER 1000":
    "https://www.spiderstaging.com/wp-content/uploads/721650-Operators-Manual-Spider-New-Yorker-SC1000-SC1500-Hoists.pdf",
  "BISO AIR MOTORS":
    "https://marrcompanies.com/wp-content/uploads/2022/02/Bisomac210-Air-Hoist-Operating-Manual.pdf",
  "BETAMAX LEO XXL VFG":
    "https://www.betamaxhoist.com/wp-content/uploads/2021/01/LEO-XXL-VFD-Parts-Manual_REV.1.4_2018.pdf",
  "BETAMAX LEO XXL":
    "https://www.betamaxhoist.com/wp-content/uploads/2021/01/LEO-XXL-VFD-Parts-Manual_REV.1.4_2018.pdf",
  "BETAMAX LEO XXL SINGLE PHASE":
    "https://www.betamaxhoist.com/wp-content/uploads/2021/01/LEO-XXL-VFD-Parts-Manual_REV.1.4_2018.pdf",

  // And so on for other categories...
  "WINSAFE 1500 SERIES OUTRIGGER SYSTEM":
    "https://skytracusa.com/wp-content/uploads/2020/04/Winsafe_Instructions_1500-Series-Modular-Outrigger-Beams-Accessories.pdf",
  "CABLE TRUSS INSTRUCTIONS":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2Fcable_truss_instructions.pdf?alt=media&token=977377b7-0550-4e3e-9406-f6de9c64ecbc",
  "13.5' REACH WINSAFE OUTRIGGER":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2Fmodularbeam.pdf?alt=media&token=24c05d18-a141-49c7-89d0-782ccae5af59",
  "WINSAFE CABLE TRUSS SYSTEM 18' OR 20'":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2FWinsafe-Cable-Truss-System-18-20.pdf?alt=media&token=3ef1863a-bca3-498c-afec-21bd8326c542",

  "ALTREX OUT RIGGER SYSTEM":
    "https://beeaccess.com/wp-content/uploads/2023/06/altrex-modular-platform-system-manual.pdf",

  "SKYCLIMBER OUTRIGGER SYSTEM":
    "https://skyclimber.com/wp-content/uploads/2019/11/SCL_AssemblyInstructions_RRR_RevAA_2019-11-18.pdf",
  "SPIDER OUTRIGGER SYSTEM":
    "https://www.spiderstaging.com/wp-content/uploads/721284.pdf",
  "TRACTLE OUTRIGGER SYSTEM":
    "https://www.tractel.com/PIM/NA_Products/NA_Technical_Data_Sheets/Permanent_Access/Davit%20and%20Outriggers/T4783_Outrigger.pdf",

  // And so on for other categories...
  "ADJUSTABLE ROPE LANYARD":
    "https://prod-edam.honeywell.com/content/dam/honeywell-edam/sps/his/en-us/products/fall-protection/documents/HIS-MF-sp450-adjustable-rope-lanyard-technical-data-sheet.pdf",
  "ALUMINUM TRIPOD":
    "https://www.wallacecranes.com/wp-content/uploads/2017/07/adjustable-height-aluminum-tripod.pdf",
  "ANCHOR SLING RATED 5,000 LBS.":
    "https://www.tractel.com/safetygate/wp-content/uploads/2018/04/T4521.pdf",
  "ANCHOR SLING RATED 10,000 LBS.":
    "https://www.tractel.com/safetygate/wp-content/uploads/2018/04/T4521.pdf",
  "ANCHORAGE D-RING":
    "https://multimedia.3m.com/mws/media/1300494O/d-ring-anchorage-connector-instruction-manual-english.pdf",
  "CONNECTORS - CARBINERS":
    "https://superanchor.com/wp-content/uploads/2019/06/2019_Product_Catalog_Vol_2_156.pdf",
  "FALLSTOP BEAM CLAMP":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2FFallstop_Beam_Clamp.pdf?alt=media&token=07312e65-2e1c-4a01-949b-3e95166081b9",
  "FALLSTOP BEAM TROLLEY":
    "https://www.engineeredfallprotection.com/pdf/3m-dbi-sala-trolley-manual.pdf?srsltid=AfmBOopIt6EQRweKVn_9XHRXlAIL1l9B4BZjdY3rC5nKJ5fSANKdyAAh",
  "FALLSTOP WIRE FORM CONNECTOR":
    "https://www.engineeredfallprotection.com/pdf/3m-dbi-sala-trolley-manual.pdf?srsltid=AfmBOopIt6EQRweKVn_9XHRXlAIL1l9B4BZjdY3rC5nKJ5fSANKdyAAh",
  "FALLSTOP WIRE ROPE GRAB":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2FTS_T4511U_US.pdf?alt=media&token=e80852f7-d9b0-4362-9ece-98bbd738350c",
  "GRIPGRAB ROPE GRAB":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2FTS_T4511U_US.pdf?alt=media&token=e80852f7-d9b0-4362-9ece-98bbd738350c",
  "TRACTEL® REBAR CHAIN ASSEMBLY":
    "https://www.tractel.com/us/product/rebar-chain-assembly-with-self-locking-snap-hook-both-ends/7596",
  "TRACTEL® REBAR WEB ASSEMBLY":
    "https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/Suspended%20Scaffolding%20Outrigger%20Systems%2FRebar_Web_Assembly.pdf?alt=media&token=578d8c3e-06b7-4a0b-95bc-21bcb8fc4488",
  "MILLER ROOFSTRIDER RM50P & RM65G SPECIFICATION SHEET":
    "https://prod-edam.honeywell.com/content/dam/honeywell-edam/sps/his/en-us/products/fall-protection/documents/HIS-MF-rm50p-rm65g-specification-sheet.pdf",
};

function Products() {
  const productCategories = [
    {
      title: "Suspended Scaffolding Platforms",
      items: [
        "SKYCLIMBER MKD MODULAR PLATFORM MANUAL",
        "SKY CLIMBER SKY STAGE ULTRA (SSU) MANUAL",
        "SKY CLIMBER SERVICE BULLETIN #151",
        "ALTREX, BEE ACCESS MANUAL",
        "ALL ALTREX MANUALS",
        "WINSAFE ALL MANUALS",
        "WINSAFE SUPERMOD PLATFORMS MANUAL",
        "WINSAFE SUPERMOD PLATFORM OPERATING INSTRUCTIONS",
        "TRACTEL® THRUSAFe® MODULAR PLATFORM FOR CONFINED SPACES",
        "TRACTEL® SOLSIT® BOSUN CHAIR MANUAL",
        "TRACTEL® SKYSAFE® MODULAR AND KNOCKDOWN PLATFORMS MANUAL",
        "TRACTEL® MODULAR SCAFFOLD VARIANCE",
        "LOUISVILLE PLATFORMS (ROPE SCAFFOLD)",
      ],
    },
    {
      title: "Suspended Scaffolding Motors",
      items: [
        "TRACTEL® TIRAK® TE MANUAL",
        "TRACTEL® TIRAK MOTORS 2015 EDITION MANUAL",
        "TRACTEL® TMS-600 MANUAL HOIST MANUAL",
        "SKYCLIMBER ALPHA HOIST 1000",
        "SKYCLIMBER CX 1250 HOIST",
        "SKYCLIMBER® MODEL 4 HOIST",
        "SPIDER SC1000 / SC1500 OPERATOR'S MANUAL",
        "SKYCLIMBER COMPACT 750 HOIST MANUAL",
        "SKYCLIMBER COMPACT 1000 HOIST MANUAL",
        "GRIPHOIST MECHANICAL HOIST MANUAL",
        "LNX OPERATOR'S MANUAL 03112014",
        "GENERATORS QUICK REFERENCE",
        "POWER CLIMBER 1000 PC1",
        "SPIDER NEW YORKER 1000",
        "BISO AIR MOTORS",
        "BETAMAX LEO XXL VFG",
        "BETAMAX LEO XXL",
        "BETAMAX LEO XXL SINGLE PHASE",
      ],
    },
    {
      title: "Suspended Scaffolding Outrigger Systems",
      items: [
        "WINSAFE 1500 SERIES OUTRIGGER SYSTEM",
        "CABLE TRUSS INSTRUCTIONS",
        "13.5' REACH WINSAFE OUTRIGGER",
        "WINSAFE CABLE TRUSS SYSTEM 18' OR 20'",
        "ALTREX OUT RIGGER SYSTEM",
        "SKYCLIMBER OUTRIGGER SYSTEM",
        "SPIDER OUTRIGGER SYSTEM",
        "TRACTLE OUTRIGGER SYSTEM",
      ],
    },
    {
      title: "Suspended Scaffolding Accessories",
      items: [
        "ADJUSTABLE ROPE LANYARD",
        "ALUMINUM TRIPOD",
        "ANCHOR SLING RATED 5,000 LBS.",
        "ANCHOR SLING RATED 10,000 LBS.",
        "ANCHORAGE D-RING",
        "CONNECTORS - CARBINERS",
        "FALLSTOP BEAM CLAMP",
        "FALLSTOP BEAM TROLLEY",
        "FALLSTOP WIRE FORM CONNECTOR",
        "FALLSTOP WIRE ROPE GRAB",
        "GRIPGRAB ROPE GRAB",
        "TRACTEL® REBAR CHAIN ASSEMBLY",
        "TRACTEL® REBAR WEB ASSEMBLY",
        "MILLER ROOFSTRIDER RM50P & RM65G SPECIFICATION SHEET",
      ],
    },
  ];

  return (
    <div className="products-page">
      <h1>Our Products</h1>
      {productCategories.map((category, index) => (
        <div key={index} className="category">
          <h2 className="category-title">{category.title}</h2>
          <div className="category-content">
            <ul className="category-list">
              {category.items.map((item, idx) => (
                <li key={idx} className="item">
                  <a
                    href={productLinks[item]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="item-link"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <img
              src={productImages[category.title]}
              alt={category.title}
              className="category-image"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Products;
