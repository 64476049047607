import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-container">
            <header className="about-header">
                <h1>About Us</h1>
            </header>
            <section className="about-content">
                <div className="about-image">
                    <img src="https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FKWrxMRD%20-%20Imgur.png?alt=media&token=df5677c1-c2b4-4abd-b75e-ae2b03f6228b" alt="Hexagon Scaffolding Logo" />
                </div>
                <div className="about-text">
                    <h2>Excellence in Scaffolding Solutions</h2>
                    <p>Hexagon Scaffolding, LLC, is a recognized leader in providing suspended and hanging scaffolding solutions in the New York City area.</p>
                    <p>Over the last 5 years, we have built a reputation for delivering high-quality scaffolding products and unparalleled service throughout the New York metropolitan area and neighboring states.</p>
                    <p>We specialize in <strong>Rental</strong>, <strong>Sales</strong>, <strong>Service & Maintenance</strong>, <strong>Installation</strong>, <strong>Engineering</strong>, and <strong>Training</strong>. Our goal is to offer comprehensive solutions to meet every scaffolding requirement you may have.</p>
                    <p>We provide 24-hour emergency services and can service not only our own equipment but also that of most competitors. This commitment to service is why our customers choose Hexagon Scaffolding time and time again.</p>
                    <p>We are committed to delivering unmatched customer service and supporting our clients when other scaffolding companies cannot. We believe in treating every customer like family, ensuring a supportive and efficient experience every time.</p>
                    <p>If you have any questions or would like to discuss renting or purchasing scaffolding, feel free to reach out. Our dedicated team is here to help with all your scaffolding needs.</p>
                    <p>Visit us at our facility located at 369 Jelliff Ave, Newark, NJ 07108, to see our fully maintained rental fleet ready to go at a moment's notice.</p>
                </div>
            </section>
            <section className="business-hours">
                <h2>Business Hours</h2>
                <div className="hours-table">
                    <div className="hours-column">
                        <h3>OFFICE HOURS</h3>
                        <p><strong>Summer Schedule (April - September)</strong></p>
                        <ul>
                            <li>Monday - Friday: 6:00 AM - 11:30 AM</li>
                            <li>Saturday - Sunday: CLOSED</li>
                        </ul>
                    </div>
                    <div className="hours-column">
                        <h3>SHOP HOURS</h3>
                        <p><strong>Winter Schedule (Starts Monday after Thanksgiving)</strong></p>
                        <ul>
                            <li>Monday - Friday: 7:00 AM - 3:30 PM</li>
                            <li>Saturday - Sunday: CLOSED</li>
                        </ul>
                    </div>
                </div>
                <p>Our office is open from Monday through Friday, 6 AM to 4:30 PM, and most Saturdays during the summer. We provide delivery, pick-up, and rental returns during these hours. Extended hours may be available to accommodate your needs, though additional service charges may apply.</p>
                <p>For urgent requirements, we offer 24-hour emergency service. Just leave us a voicemail, and our team will get back to you promptly.</p>
            </section>
        </div>
    );
};

export default AboutUs;
